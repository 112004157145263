<template>
  <div>
    <result-add
      v-if="isAddNewResultSidebarActive"
      :is-add-new-result-sidebar-active.sync="isAddNewResultSidebarActive"
      :items="items"
      :slot-id="slotId"
      :squad-data="squadData"
    />
    <b-card-code
      title="Player List"
      no-body
    >
      <b-card-body>
        <div class="d-flex justify-content-between  flex-wrap">

          <!-- filter -->
          <b-form-group
            label="Filter"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-card-body>

      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(action)="data">
          <span>
            <b-badge
              variant="warning"
              style="margin-right: 2px;margin-bottom: 2px"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Edit Result'"
                class="btn-link cursor-pointer text-white"
                icon="EditIcon"
                @click="editResult(data)"
              />
            </b-badge>
          </span>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>

    </b-card-code>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, VBTooltip, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody,
} from 'bootstrap-vue'
import { useToast } from 'vue-toastification/composition'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { onUnmounted, ref } from '@vue/composition-api'
import matchStoreModule from '@/views/matches/matchStoreModule'
import store from '@/store'
import ResultAdd from '@/views/matches/result/ResultAdd.vue'

const toast = useToast()

export default {
  components: {
    BCardCode,
    BTable,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ResultAdd,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'isAddNewResultSidebarActive',
    event: 'update:is-add-new-result-sidebar-active',
  },
  data() {
    return {
      squadData: [],
      slotId: '',
      currentPlayerData: [],
      perPage: 25,
      pageOptions: [25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        {
          key: 'slot_no', label: 'Slot No',
        },
        {
          key: 'pubg_name', label: 'Player',
        },
        { key: 'kills', label: 'Kills', sortable: true },
        { key: 'rank', label: 'Rank', sortable: true },
        { key: 'action', label: 'Action' },
      ],
      items: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    items() {
      this.$emit('update:items', this.items)
    },
  },
  mounted() {
    this.getPlayerList()
  },
  setup() {
    const REFERRAL_APP_STORE_MODULE_NAME = 'app-matches'
    // eslint-disable-next-line no-unused-vars
    const isAddNewResultSidebarActive = ref(false)
    // Register module
    if (!store.hasModule(REFERRAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(REFERRAL_APP_STORE_MODULE_NAME,
        matchStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REFERRAL_APP_STORE_MODULE_NAME)) store.unregisterModule(REFERRAL_APP_STORE_MODULE_NAME)
    })

    return {
      isAddNewResultSidebarActive,
    }
  },
  methods: {
    editResult(data) {
      this.slotId = data.item.slot_no
      this.getSquadBySlot()
      this.isAddNewResultSidebarActive = true
    },
    getSquadBySlot() {
      this.squadData = []
      const self = this
      const tempData = this.items
      tempData.forEach(value => {
        if (value.slot_no === self.slotId) {
          self.squadData.push({
            kills: value.kills,
            player_name: value.pubg_name,
            rank: value.rank,
            id: value.id,
          })
        }
      })
    },
    getPlayerList() {
      store
        .dispatch('app-matches/fetchMatchUsers', {
          matchId: this.$route.params.id,
        })
        .then(response => {
          this.items = response.data.data.players.users
          this.totalRows = response.data.data.players.filled
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching matches',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
