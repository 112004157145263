<template>
  <div id="app">
    <file-pond
      ref="pond"
      name="file"
      label-idle="Upload Result Images Here..."
      :allow-multiple="true"
      accepted-file-types="image/jpeg, image/png"
      :server="apiURL"
      credits="false"
    />
  </div>
</template>

<script>

import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import useJwt from '@/auth/jwt/useJwt'

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
)

export default {
  components: {
    // BFormFile,
    FilePond,
    // BCol,
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      apiURL: {
        url: `${process.env.VUE_APP_API_URL}`,
        process: {
          url: '/match/resultImageUpload',
          headers: {
            Authorization: `Bearer ${useJwt.getToken()}`,
          },
          onload: response => {
            this.images.push(JSON.parse(response).data.file_path)
          }, // saving response in global array
        },
      },
    }
  },
  watch: {
    items() {
      this.$emit('update:images', this.images)
    },
  },
}

</script>

<style scoped>

</style>
