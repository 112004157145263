<template>
  <div>
    <form-wizard
      color="#ff9900"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3"
      @on-complete="formSubmitted"
    >

      <!-- details tab -->
      <tab-content
        title="Image Upload"
        icon="feather icon-file-text"
      >
        <result-image-add
          :match-id="$route.params.id"
          :images.sync="images"
        />
      </tab-content>
      <!-- Price Content -->
      <tab-content
        title="Result"
        icon="feather icon-check-circle"
      >
        <result-list
          :match-id="$route.params.id"
          :items.sync="items"
        /></tab-content>
      <!-- Rules  -->
      <tab-content
        title="Confirmation"
        icon="feather icon-check-circle"
      >
        <result-preview
          :results="results"
        />
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { onUnmounted } from '@vue/composition-api'
import ResultList from '@/views/matches/result/ResultList.vue'
import ResultImageAdd from '@/views/matches/result/ResultImageAdd.vue'
import ResultPreview from '@/views/matches/result/ResultPreview.vue'
import store from '@/store'
import matchStoreModule from '../matchStoreModule'

export default {
  components: {
    FormWizard,
    TabContent,
    ResultList,
    ResultImageAdd,
    ResultPreview,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      items: [],
      images: [],
    }
  },
  computed: {
    results() {
      const res = this.items
      return res.sort((a, b) => a.rank - b.rank)
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-matches'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, matchStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
  },
  methods: {
    formSubmitted() {
      this.$store.dispatch('app-matches/addResult', { items: this.items, images: this.images, match_id: this.$route.params.id })
        .then(res => {
          if (res.data.success) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Match Result Added',
                icon: 'BellIcon',
                variant: 'success',
                text: 'Match result added successfully',
              },
            })
            this.$router.push('/matches/list')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Match Result Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: 'Please try again after sometime',
              },
            })
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Oops! Match Result Adding Failed',
              icon: 'BellIcon',
              variant: 'danger',
              text: 'Please try again after sometime',
            },
          }, {
            timeout: 3000,
          })
        })
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-wizard.scss';
    @import '@core/scss/vue/libs/vue-select.scss';
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }
</style>
