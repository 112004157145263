<template>
  <b-sidebar
    id="add-new-result-sidebar"
    :visible="isAddNewResultSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-result-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Update Result
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @reset.prevent="resetForm"
        >
          <span class="font-weight-bold h5">Slot No : {{ slotId }}</span> <br>
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Squad Position"
            rules="required|numeric"
          >
            <b-form-group
              label="Squad Position"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="squadData[0].rank"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="eg: 2"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <div
            v-for="(value,key) in squadData"
            :key="key"
          >
            <!-- Username -->
            <validation-provider
              #default="validationContext"
              name="Squad Kills"
              rules="required"
            >

              <b-form-group
                :label="(value.player_name)+' kills'"
                label-for="value.player_name"
              >
                <b-form-input
                  id="value.player_name"
                  v-model="squadData[key].kills"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="5"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              @click="formSubmitted"
            >
              Update Result
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import { numeric } from 'vee-validate/dist/rules'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

extend('numeric', numeric)

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewResultSidebarActive',
    event: 'update:is-add-new-result-sidebar-active',
  },
  props: {
    isAddNewResultSidebarActive: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    squadData: {
      type: Array,
      default: () => ([]),
    },
    slotId: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      required,
      squad: {},
    }
  },
  setup(props) {
    const blankSquad = {}
    // console.log(props)
    const squad = ref(JSON.parse(JSON.stringify(blankSquad)))
    const resetuserData = () => {
      // roomData.value = JSON.parse(JSON.stringify(blankRoomData))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      squad,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods: {
    formSubmitted() {
      const self = this
      this.items.forEach((player, key) => {
        if (player.slot_no === self.slotId) {
          self.squadData.forEach(value => {
            if (value.id === player.id) {
              self.items[key].kills = value.kills
              self.items[key].rank = this.squadData[0].rank
            }
          })
        }
      })
      this.$emit('update:is-add-new-result-sidebar-active', false)
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';

    #add-new-user-sidebar {
        .vs__dropdown-menu {
            max-height: 200px !important;
        }
    }
</style>
