<template>
  <div>{{ results }}</div>
</template>

<script>
export default {
  name: 'ResultPreview',
  props: {
    results: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
